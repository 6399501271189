<template>
  <div id="AddUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12 color-bg-4">
        <Header
                :name="user.fullname"
                /> 
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
           <div id="" class="">
           <SideMenu :indexsec="7"/>
          </div>
          <div id="stn-edituser" class="stn_edituserAdd">
           
            <form
              @submit.prevent="
                submit(
                 title_form,
                 file1,
                 autor_form,
                 date_form,
                 content
                )
              "
            >
            <div class="div_EProduct">
              <p>AGREGAR BLOG</p>
              </div>


              <div class="row mx-0 row-one">
                <div class="EPF1_C1">
                  <label class="labelN color-1" for="inputN">Título</label>
                  <b-form-input
                   
                    v-model="title_form"
                    required
                    id="inputN"
                    type="text"
                  />
                </div>

                <div class="div-image QUITLEFT">
                  <label class="labelImg color-1" for="inputImg">Imagen cabecera</label>
                  <b-form-file
                    v-model="file1"
                    id="inputImg"
                    required
                    type="file"
                    name="inputImg"
                    :placeholder="'Subir imagen'"
                  />
                </div>

                 
                
              </div>

                <div class="row mx-0 row-two">
                  <div class="EPF1_C1">
                      <label class="labelR color-1" for="inputR">Autor</label>
                    
                      <b-dropdown>
                        <template #button-content>
                          <div class="row"><span class="drop-text">{{autor_name}}</span></div> 
                        </template>

                        <div v-for="(item, key) in this.users" :key="key">
                            <b-dropdown-item @click="changeAutor(item.id, item.name)">
                              {{item.name}}
                            </b-dropdown-item>
                        </div>
                      </b-dropdown>
                    </div>
                    <div class="">
                  <label class="labelA color-1" for="inputA"
                    >Fecha</label
                  >
                  <b-form-input
                    v-model="date_form"
                    required
                    
                    id="inputA"
                    type="date"
                   />
                  </div>
                </div>


            <label class="labelA color-1 mtrow EPF1_C1centrado">Contenido</label>
             <vue-editor
                 id="editor"
                 class="quill-editor"
                useCustomImageHandler
                @image-added="handleImageAdded"
                v-model="content"
                >
              </vue-editor> 
              
                <!--   -->


               <div class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>
              <div class="btn-form">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">La entrada se agrego correctamente</p>
                  </div>
                  <div class="">
                    <div class="btns-dflx">
                        <b-button class="btn-modal" @click="agregarOtro()" >AÑADIR OTRA</b-button>
                                       
                        <b-button class="btn-modal"  @click="toEdit()">EDITAR</b-button>
                        
                        <b-button class="btn-modal" @click="toPage()">VER ENTRADA</b-button>
                     </div>

                  
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
        
            
              
            
            
           


              <label h2 id="prev-title" class="pvz-title color-1 EPF1_C1centrado">Previsualización</label>
              <div id="prev_quill" v-html="content">
              </div>

             <label id="prev-title" class="html-title color-1 EPF1_C1centrado">HTML</label>
             <div id="prev_quill">
               {{content}}
             </div>
  
          
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideMenu from '../../components/SideMenu';
import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import Header from '../../components/Header';
import { mapActions } from "vuex";

import { VueEditor } from "vue2-editor";



var toolbarOptions = [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],

                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction

                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [ 'link','image' ,'video' ],          // add's image support
                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],

                ['clean']                                         // remove formatting button
            ];
export default {
  name: "AddItem",
  components: {
    Header,
    VueEditor,
    SideMenu,
  },

  data() {
    return {
      autor_form:"",
      autor_name:"Autor",
      date_form:'',
      title_form:"",
      status: "",
      msg: "",
      file1:null,
      logo: logo,
      legrafica: legrafica,
      IconSuccess: IconSuccess,
      added:'',
      
                 //editor
      content: "",
      editorOption: { 
          modules: {
                    toolbar: toolbarOptions
                },

                theme: 'snow'
        }, // Elemento de configuración del editor 
    // pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}{/()+*-_}"

    };
  },async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
  created() {
     this.getUsers('usuarios')
    
  },
  computed:{
      users() {
          return this.$store.getters["users/data"];
        },
        url() {
      return this.$store.getters["main/baseURL"];
    },
        user() {            
            return this.$store.getters['admin/getIdentity'];
        },
  },
  methods: {
     ...mapActions('users', ['getUsers']),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    toPage: function () {
       this.$router.push("/blog/titulo/"+this.added.url).catch((err) => {});
    },

    
    filterTitle: function(title) {
      let result = title.replaceAll(" ", "-").replaceAll('/','_');
      return result;
    },

    toEdit: function () {
       this.$router.push("/editar/entrada/"+this.added.id).catch((err) => {});
    },


    submit: async function (  title, image, autor, date, content) {
     

          this.status=''
          this.msg=''

    /*  let dateSplit = date.split('-')
       let yy = dateSplit[0]
       let mm = dateSplit[1]
       let dd = dateSplit[2]
       date = dd+'/'+mm+'/'+yy */

        if(content =='' || content ==undefined || content==null){
          this.status='error'
          this.msg='El contenido de la entrada está vacío o no es valido.'
        }else if(autor=='' || autor == undefined || autor==null){

         this.status='error'
          this.msg='No se a asignado un autor a la entrada.'
        }else if(image == null){
            this.status='error'
            this.msg='Imagen no subida'
        }else{

            if(image.size > 1000000){
                this.status='error'
                this.msg= 'Tamaño de imagen no válido, sube imagenes menores a 1 MB.'
               
                
            }else{
                var data = new  FormData();
              
                  data.append('image', image);
                  data.append('title',title);
                  data.append('dateblog', date);
                  data.append('content',content);
                  data.append('id_user',autor)
                  data.append('_method', 'POST');
                
                      
                  this.status=''
                  this.msg=''
              
                  let result = await this.$store.dispatch("main/addItem",  {option:'blog', item: data});
                
                  if(result.status=='error'){
                    this.status='error'
                    this.msg= result.message
                  }else{ // success
                      this.content=''
                      this.added = result.added
                      this.showModal() 
                  }
            }

        }

    },
    agregarOtro: function () {
      this.wait();
    },
    salir: function () {
       this.$router.push("/administrador").catch((err) => {});
    },
    showModal(){
       this.$refs['modal-editar'].show()
    },
     changeAutor: function (value, name) {
     
       this.autor_form = value;
      this.autor_name = name;

    },



    handleImageAdded: async function(file, Editor, cursorLocation, resetUploader) {
     
        let url

         var data = new  FormData();
          data.append('image', file );
          data.append('_method', 'POST');

          let result = await  this.$store.dispatch("main/uploadImage",  {option:'blog', data:data});
            
              if(result.data.status=='error'){
                this.status='error'
                this.msg= 'Imagen no valida.'
              }else{ // success
            
                   url = this.url + '/get-blog-image/' + result.data.fileName
                   Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
              }
    
    },

    

  },
};
</script>
